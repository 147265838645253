import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import MainLayout from './main'
import LoginLayout from './login'

const mapStateToProps = ({ user }) => ({ user })

const Layouts = {
  login: LoginLayout,
  main: MainLayout,
}

const IndexLayout = ({ user, children, location: { pathname } }) => {
  // Layout Rendering
  const getLayout = () => {
    if (/^\/secured(?=\/|$)/i.test(pathname)) {
      return 'login'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const userInfo = user.user
  const isUserLoading = user.loading
  const isAuthLayout = getLayout() === 'login'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      return <Redirect to="/secured/login" />
    }
    // review and fix memory leak
    if (pathname === '/secured/account/init' && isUserAuthorized && userInfo.is_init_account === 1) {
      return <Redirect to="/" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <>
      <Helmet titleTemplate="%s | Tradiry" title="Tradiry" />
      {BootstrappedLayout()}
    </>
  )
}

export default withRouter(connect(mapStateToProps)(IndexLayout))
