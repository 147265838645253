import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout } from 'antd'

const mapStateToProps = () => ({})

const LoginLayout = ({ children }) => (
  <Layout className="h-100vh">
    <Layout.Content>{children}</Layout.Content>
  </Layout>
)

export default withRouter(connect(mapStateToProps)(LoginLayout))
