// eslint-disable-next-line import/prefer-default-export
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost'

export const colors = {
  primaryColor: '#007be8',
  primaryHover: '#42baf9',
  successColor: '#28a745',
  dangerColor: '#ea2c54',
  dangerHover: '#e73057',
  warningColor: '#faad15',
  purpleColor: '#7927a2',
  gridLinesColor: '#e4e8f1',
  labelsColor: '#c8c4db',
  fontColor: '#141333',
  valueColor: '#475f7b',
  labelColor: '#596778',
  grayLight: '#828D99',
  fontFamily: 'Nunito Sans, Roboto, Arial, sans-serif',
}
