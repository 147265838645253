import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { PageHeader, Row, Col } from 'antd'

import { API_URL } from '../../config/app'
import getAPITokenFromLocalStorage from '../../helpers/getAPITokenFromLocalStorage'

import DashboardStats from '../../components/ui/widgets/DashboardStats'
import ActiveUsersList from '../../components/ui/widgets/dashboard/ActiveUsersList'

// eslint-disable-next-line no-empty-pattern
const mapStateToProps = ({}) => ({})

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: {},
      loading: true,
    }
  }

  componentDidMount() {
    axios
      .get(`${API_URL}/api/v1/admin`, getAPITokenFromLocalStorage(true))
      .then((res) => {
        if (res.data.success) {
          this.setState({ loading: false, data: res.data.data })
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e)
      })
  }

  render() {
    const pageTitle = 'Dashboard'

    const { loading, data } = this.state

    if (loading) {
      return 'Loading...'
    }
    return (
      <>
        <Helmet title={pageTitle} />
        <PageHeader backIcon={false} title={pageTitle} className="p-l-0 p-r-0" />
        <Row gutter={30}>
          <Col span={12} className="m-b-30">
            <DashboardStats data={data.appStats} />
          </Col>
          <Col span={12}>
            <ActiveUsersList data={data.activeUsers.today} title="Today's Active Users" />
            <ActiveUsersList data={data.activeUsers.yesterday} title="Yesterday Active Users" />
            <ActiveUsersList data={data.activeUsers.lastWeek} title="Last 7 Days Active Users" />
          </Col>
        </Row>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(Dashboard))
