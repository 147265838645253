/* eslint no-unused-vars:0 */
import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Form, Input, Button, message } from 'antd'

import actions from '../../../redux/user/actions'

const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
})

const LoginForm = ({ dispatch, user }) => {
  const onFinish = (values) => {
    dispatch({
      type: actions.LOGIN,
      payload: {
        email: values.email,
        password: values.password,
      },
    })
  }

  const onFinishFailed = () => {
    message.error({ content: 'Something goes wrong...', key: 'admin_login' })
  }

  const validateMessages = {
    required: 'This field is required!',
    types: {
      email: 'Enter an email address',
    },
  }

  return (
    <Row>
      <Col span={6} offset={9} className="m-t-100 m-b-100">
        <Card>
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="mb-4"
            validateMessages={validateMessages}
          >
            <Form.Item
              name="email"
              hasFeedback
              rules={[{ required: true, message: 'Please input your e-mail address' }, { type: 'email' }]}
            >
              <Input size="large" placeholder="Email" />
            </Form.Item>
            <Form.Item name="password" hasFeedback rules={[{ required: true, message: 'Please input your password' }]}>
              <Input size="large" type="password" placeholder="Password" />
            </Form.Item>
            <Form.Item className="m-b-0">
              <Button
                type="primary"
                size="large"
                className="text-center w-100 fw-600"
                htmlType="submit"
                loading={user.loading}
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default connect(mapStateToProps)(LoginForm)
