import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import LoginForm from '../../../components/ui/auth/LoginForm'

// eslint-disable-next-line no-empty-pattern
const mapStateToProps = () => ({})

class LoginPage extends React.Component {
  render() {
    const pageTitle = 'Login'
    const { dispatch } = this.props
    return (
      <>
        <Helmet title={pageTitle} />
        <LoginForm dispatch={dispatch} />
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(LoginPage))
