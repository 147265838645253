import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Skeleton, Table } from 'antd'

import { API_URL } from '../../../config/app'
import getAPITokenFromLocalStorage from '../../../helpers/getAPITokenFromLocalStorage'

// eslint-disable-next-line no-restricted-globals
const sorterHelper = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b)

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Trades',
    dataIndex: 'trades',
    key: 'trades',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => sorterHelper(a.trades, b.trades),
  },
  {
    title: 'Is Verified',
    dataIndex: 'isVerified',
    key: 'isVerified',
  },
  {
    title: 'Is Init',
    dataIndex: 'isInit',
    key: 'isInit',
  },
  {
    title: 'Premium',
    dataIndex: 'premium',
    key: 'premium',
  },
  {
    title: 'Last Activity',
    dataIndex: 'lastActivity',
    key: 'lastActivity',
  },
  {
    title: 'Account Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
]

const UsersList = () => {
  const [loading, handleLoading] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [users, handleUsers] = useState([])

  useEffect(() => {
    axios
      .get(`${API_URL}/api/v1/admin/users?sortBy=created_at`, getAPITokenFromLocalStorage(true))
      .then((res) => {
        if (res.data.success && res.data.users) {
          handleLoading(false)
          handleUsers(res.data.users)
        }
      })
      .catch(() => {
        handleLoading(false)
      })
  }, [])

  const renderTable = () => {
    if (!loading && users.length > 0) {
      const dataSource = []
      // eslint-disable-next-line no-unused-vars
      users.forEach((user) => {
        dataSource.push({
          name: user.first_name ? `${user.first_name} ${user.last_name}` : 'None',
          email: user.email,
          trades: user.get_trades_count,
          isVerified: user.email_verified_at ? 'Yes' : 'No',
          isInit: user.is_init_account ? 'Yes' : 'No',
          premium: user.is_premium ? 'Yes' : 'No',
          lastActivity: user.last_activity
            ? moment(user.last_activity).format('DD-MM-YYYY HH:mm')
            : 'No activity found',
          createdAt: moment(user.created_at).format('DD-MM-YYYY HH:mm'),
        })
      })
      return (
        <>
          <div>Total registered users: {users.length}</div>
          <div>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
            />
          </div>
        </>
      )
    }
    return null
  }

  if (loading) {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </>
    )
  }

  return renderTable()
}

export default UsersList
