/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Bar } from 'react-chartjs-2'
import { Card, Skeleton, message } from 'antd'

import { API_URL, colors } from '../../../config/app'
import getAPITokenFromLocalStorage from '../../../helpers/getAPITokenFromLocalStorage'

const RegisteredUsers = () => {
  const [days, handleDays] = useState(60)
  const [data, handleData] = useState([])
  const [loading, handleLoading] = useState(true)

  const options = {
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
          },
        },
      ],
    },
  }

  const barChartData = {
    labels: [],
    datasets: [
      {
        label: 'Registered',
        backgroundColor(context) {
          const index = context.dataIndex
          const value = context.dataset.data[index]
          return value < 0 ? colors.dangerColor : colors.successColor
        },
        borderColor(context) {
          const index = context.dataIndex
          const value = context.dataset.data[index]
          return value < 0 ? colors.dangerColor : colors.successColor
        },
        borderWidth: 1,
        maxBarThickness: 45,
        data: [],
      },
    ],
  }

  useEffect(() => {
    axios
      .get(`${API_URL}/api/v1/admin/widgets/users-registered?days=${days}`, getAPITokenFromLocalStorage(true))
      .then((res) => {
        if (res.data.success) {
          handleData(res.data.data)
          handleLoading(false)
        }
      })
      .catch(() => {
        message.error('Something goes wrong')
      })
  }, [])

  const renderChart = () => {
    data.forEach((value) => {
      barChartData.labels.push(value.date)
      barChartData.datasets[0].data.push(value.users)
    })
    return (
      <div className="h-500">
        <Bar data={barChartData} options={options} />
      </div>
    )
  }

  return (
    <Card title={`New Users Last ${days} days`} className="m-b-30">
      {loading ? <Skeleton active /> : renderChart()}
    </Card>
  )
}

export default RegisteredUsers
