import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'

import { history } from 'index'

import axiosRequest from '../../helpers/axios-helper'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  try {
    // Set loading
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })
    // API request
    const login = yield call(axiosRequest, '/api/v1/auth/login-admin', 'post', {
      email,
      password,
    })
    // Check if success
    if (login.data && login.data.access_token) {
      const loginResponse = login.data
      localStorage.setItem('apiToken', loginResponse.access_token)
      yield put({
        type: 'user/SET_STATE',
        payload: { loading: false, authorized: true, user: loginResponse.user },
      })
      yield history.push('/')
    }
  } catch (e) {
    // Return error
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })
    notification.error({
      message: 'Login failed',
      description: 'These credentials do not match our records',
    })
  }
}

/**
 * Check user auth status on app load
 */
export function* GET_CURRENT_ACCOUNT_OR_FAIL() {
  const apiToken = localStorage.getItem('apiToken')
  if (!apiToken) {
    yield history.push('/secured/login')
  } else {
    try {
      yield put({ type: 'user/SET_STATE', payload: { loading: true } })
      const authCheck = yield call(axiosRequest, '/api/v1/user-check', 'get', null, true)
      if (authCheck.data) {
        yield put({
          type: 'user/SET_STATE',
          payload: { loading: false, authorized: true, user: authCheck.data.user },
        })
      }
    } catch (e) {
      yield put({ type: 'user/SET_STATE', payload: { loading: false, authorized: false } })
      localStorage.removeItem('apiToken')
      // Disable redirect to login page for pages with "secured" in URL
      if (!/^\/secured(?=\/|$)/i.test(history.location.pathname)) {
        yield history.push('/secured/login')
      }
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOGIN, LOGIN), GET_CURRENT_ACCOUNT_OR_FAIL()])
}
