import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout, Menu } from 'antd'
import { DashboardOutlined, UserOutlined, AreaChartOutlined } from '@ant-design/icons'

const mapStateToProps = () => ({})

const MainLayout = ({ children }) => (
  <Layout className="h-100vh">
    <Layout.Header>
      <div className="logo white-color font-size-26">tradiry.</div>
    </Layout.Header>
    <Layout>
      <Layout.Sider width={200} className="site-layout-background">
        <Menu theme="dark" style={{ height: '100%', borderRight: 0 }}>
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="statistics" icon={<AreaChartOutlined />}>
            <Link to="/statistics">Statistics</Link>
          </Menu.Item>
          <Menu.Item key="users-list" icon={<UserOutlined />}>
            <Link to="/users">Users</Link>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout>
        <Layout.Content className="p-t-30 p-l-30 p-r-30 p-b-30">{children}</Layout.Content>
      </Layout>
    </Layout>
  </Layout>
)

export default withRouter(connect(mapStateToProps)(MainLayout))
