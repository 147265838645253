import axios from 'axios'

import { API_URL } from '../config/app'

const axiosRequest = (url, method, data = null, needBearerToken = false) => {
  let headers = {}
  const bearerToken = localStorage.getItem('apiToken')
  if (needBearerToken) {
    headers = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }
  }
  switch (method) {
    case 'get':
      return axios.get(API_URL + url, headers)
    case 'post':
      return axios.post(API_URL + url, data, headers)
    case 'put':
      return axios.put(API_URL + url, data, headers)
    case 'patch':
      return axios.patch(API_URL + url, data, headers)
    case 'delete':
      return axios.delete(API_URL + url, { data: {}, headers: { Authorization: `Bearer ${bearerToken}` } })
    default:
      return null
  }
}

export default axiosRequest
