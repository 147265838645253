import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import IndexLayout from './layouts/IndexLayout'

import LoginPage from './pages/auth/login'
import Dashboard from './pages/dashboard'
import Statistics from './pages/statistics'
import UsersPage from './pages/users/list'

const routes = [
  // Auth routes
  {
    path: '/secured/login',
    Component: LoginPage,
    exact: true,
  },
  {
    path: '/dashboard',
    Component: Dashboard,
    exact: true,
  },
  {
    path: '/statistics',
    Component: Statistics,
    exact: true,
  },
  {
    path: '/users',
    Component: UsersPage,
    exact: true,
  },
]

const mapStateToProps = () => ({
  routerAnimation: 'slide-fadein-up',
})

const Router = ({ history, routerAnimation }) => (
  <ConnectedRouter history={history}>
    <IndexLayout>
      <Route
        render={(state) => {
          const { location } = state
          return (
            <SwitchTransition>
              <CSSTransition
                key={location.pathname}
                appear
                classNames={routerAnimation}
                timeout={routerAnimation === 'none' ? 0 : 300}
              >
                <Switch location={location}>
                  <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                  {routes.map(({ path, Component, exact }) => (
                    <Route
                      path={path}
                      key={path}
                      exact={exact}
                      render={() => (
                        <div className={routerAnimation}>
                          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                          <Component />
                        </div>
                      )}
                    />
                  ))}
                  <Redirect to="/404" />
                </Switch>
              </CSSTransition>
            </SwitchTransition>
          )
        }}
      />
    </IndexLayout>
  </ConnectedRouter>
)

export default connect(mapStateToProps)(Router)
