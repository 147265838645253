import React from 'react'
import moment from 'moment'

const ActiveUsersList = ({ data, title = 'Users' }) => {
  const renderList = () =>
    data.map((user) => (
      <tr key={user.id}>
        <td>{user.id}</td>
        <td>
          <p className="m-b-0">
            {user.first_name || 'Undefined'} {user.last_name || 'User'} {user.is_premium && <sup><strong>Premium</strong></sup>}
          </p>
          <p className="m-b-0">
            <small>{user.email}</small>
          </p>
        </td>
        <td>Trades: {user.get_trades_count}</td>
        <td>{user.get_country && user.get_country.name ? user.get_country.name : '-'}</td>
        <td>{moment(user.created_at).format('DD-MM-YYYY')}</td>
      </tr>
    ))

  return (
    <div className="ant-table m-b-30">
      <div className="ant-table-container">
        <div className="ant-table-content">
          <table className="table">
            <thead className="ant-table-thead">
              <tr>
                <th colSpan="5">{title}</th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">{renderList()}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ActiveUsersList
