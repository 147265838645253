import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { PageHeader } from 'antd'

import UsersList from '../../components/ui/users/UsersList'

// eslint-disable-next-line no-empty-pattern
const mapStateToProps = () => ({})

class UsersPage extends React.Component {
  render() {
    const pageTitle = 'Users'
    return (
      <>
        <Helmet title={pageTitle} />
        <PageHeader backIcon={false} title={pageTitle} className="p-l-0 p-r-0" />
        <UsersList />
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(UsersPage))
