import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { PageHeader } from 'antd'

import RegisteredUsers from '../../components/ui/widgets/RegisteredUsers'
import CreatedTrades from '../../components/ui/widgets/CreatedTrades'
import ActiveUsers from '../../components/ui/widgets/ActiveUsers'

// eslint-disable-next-line no-empty-pattern
const mapStateToProps = ({}) => ({})

class Statistics extends React.Component {
  render() {
    const pageTitle = 'Statistics'
    return (
      <>
        <Helmet title={pageTitle} />
        <PageHeader backIcon={false} title={pageTitle} className="p-l-0 p-r-0" />
        <RegisteredUsers />
        <ActiveUsers />
        <CreatedTrades />
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(Statistics))
