import React from 'react'

const DashboardStats = ({ data }) => (
  <>
    <div className="ant-table">
      <div className="ant-table-container">
        <div className="ant-table-content">
          <table className="table">
            <thead className="ant-table-thead">
              <tr>
                <th colSpan="2">Users</th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              <tr>
                <td>Total</td>
                <td>{data.users.total}</td>
              </tr>
              <tr>
                <td>Account initialization</td>
                <td>
                  {`Sign up finished ${data.users.init.yes} (${100 - data.users.init.noPercents}%) user(-s) from ${
                    data.users.total
                  }`}
                </td>
              </tr>
              <tr>
                <td>Email verification</td>
                <td>
                  {`Email verified ${data.users.emailVerified.yes} (${
                    100 - data.users.emailVerified.noPercents
                  }%) user(-s) from ${data.users.total}`}
                </td>
              </tr>
              <tr>
                <td>Premium subscribers</td>
                <td>
                  {data.users.premium.total} ({data.users.premium.totalPercents}%)
                </td>
              </tr>
            </tbody>
            <thead className="ant-table-thead">
              <tr>
                <th colSpan="2">Accounts</th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              <tr>
                <td>Total</td>
                <td>{data.accounts.total}</td>
              </tr>
              <tr>
                <td>Per user</td>
                <td>{data.accounts.perUser}</td>
              </tr>
            </tbody>
            <thead className="ant-table-thead">
              <tr>
                <th colSpan="2">Trades</th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              <tr>
                <td>Total</td>
                <td>{data.trades.total}</td>
              </tr>
              <tr>
                <td>Per user</td>
                <td>{data.trades.perUser}</td>
              </tr>
            </tbody>
            <thead className="ant-table-thead">
              <tr>
                <th colSpan="2">Trading Systems</th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              <tr>
                <td>Total</td>
                <td>{data.tradingSystems.total}</td>
              </tr>
              <tr>
                <td>Per user</td>
                <td>{data.tradingSystems.perUser}</td>
              </tr>
            </tbody>
            <thead className="ant-table-thead">
              <tr>
                <th colSpan="2">Advanced stats</th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              <tr>
                <td>Total</td>
                <td>{data.advancedStats.total}</td>
              </tr>
              <tr>
                <td>Per user</td>
                <td>{data.advancedStats.perUser}</td>
              </tr>
              <tr>
                <td>Values</td>
                <td>{data.advancedStats.values.total}</td>
              </tr>
              <tr>
                <td>Values per stat</td>
                <td>{data.advancedStats.values.perStat}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </>
)

export default DashboardStats
